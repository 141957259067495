.EventPreview {
  @apply flex flex-col text-dark-blue h-full;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
.PictureWrapper {
  @apply aspect-w-1 aspect-h-1;
}
.PictureContent {
  @apply flex flex-col justify-between h-full pt-2;
}
.Content {
  @apply pt-2 grow relative;
  padding-bottom: calc(36px + 4 * theme(spacing.1));
}
.Status {
  margin-top: 5px;
}
.FavIcon {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  scroll-margin-top: 80px;
}
.ShareIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.EventPreview.FixedHeight .Content {
  min-height: 171px;
}
.Distances,
.Date,
.City {
  @apply text-fjord-grey;
}
.Distances,
.City {
  @apply flex flex-row items-center gap-2;
}
.Distances {
  margin-top: theme(spacing.1);
}
/* .Distances:before { */
/*   content: ""; */
/*   display: block; */
/*   flex-shrink: 0; */
/*   /* HERE */
/*   background: transparent */
/*     url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxNyAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNOC41IDE1LjJINC43YTIuOSAyLjkgMCAwIDEtMi45LTN2MGEzIDMgMCAwIDEgMy0yLjloNi43YTIuOSAyLjkgMCAwIDAgMC01LjhoLTNtMy4zIDEzLjN2LTVtMCAwSDE2djMuNGgtNC4ydi0zLjRaTTYgMy41YTIuNSAyLjUgMCAxIDEtNSAwIDIuNSAyLjUgMCAwIDEgNSAwWiIgc3Ryb2tlPSIjNDY0RTY3IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=") */
/*     no-repeat center; */
/*   background-size: contain; */
/*   width: 16px; */
/*   height: 16px; */
/* } */
.PictureSkeleton {
  line-height: 0;
}

.Tags {
  @apply pl-2 inline-flex flex-row items-center flex-nowrap gap-[6px];
}

.Disciplines {
  @apply px-2;
}

@media screen and (max-width: calc(theme(screens.sm) - 0.05px)) {
  .Tags {
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media screen(sm) {
  .Tags {
    overflow-y: auto;
  }

  .Tags {
    @apply flex-wrap;
  }

  .Tags::-webkit-scrollbar {
    width: 6px;
    padding-right: 2px;
  }

  .Tags::-webkit-scrollbar-track {
    background: transparent;
  }

  .Tags::-webkit-scrollbar-thumb {
    background-color: #f8f8fa;
    border-radius: 20px;
    border: 2px solid rgba(0, 0, 0, 0.5);
    margin-right: 2px;
  }

  .EventPreview .HiddenTag {
    display: none;
  }
  .EventPreview:hover .HiddenTag {
    display: inline-flex;
  }

  .Tags :global(.MustSeeLabel) {
    display: none;
  }

  .EventPreview:hover .Tags :global(.MustSeeLabel) {
    display: inline;
  }
}

.Tags :global(.Chip) {
  z-index: 20;
}

.EventPreview:hover .Plus {
  display: none;
}

.Actions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: calc(theme(screens.sm) - 0.05px)) {
  .Tags {
    flex-wrap: nowrap;
  }

  .Tags .Plus {
    display: none;
  }
}

.Discipline {
  @apply bg-dark-blue inline-block text-center text-white text-xs uppercase px-2 py-1;
  font-family: var(--font-family-title);
  font-weight: 600;
}

.Disciplines {
  @apply flex flex-row items-end;
  margin-right: -8px;
}
.Disciplines .Discipline {
  margin-top: 6px;
  margin-right: 8px;
}

.PlusDisciplines {
  opacity: 0.6;
}
.OtherDisciplines {
  display: none;
}

.EventPreview:hover .OtherDisciplines {
  display: contents;
}
.EventPreview:hover .PlusDisciplines {
  display: none;
}

@media screen and (max-width: calc(theme(screens.sm) - 0.05px)) {
  .Disciplines .OtherDisciplines {
    display: inline;
  }
  .Disciplines .PlusDisciplines {
    display: none;
  }
  .Disciplines {
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
