.Content {
  z-index: 1;
  position: relative;
}

.Section {
  position: relative;
}

.Section {
  padding: 104px 0;
}

@media screen and (max-width: calc(theme(screens.md) - 0.05px)) {
  .Section {
    padding: 74px 0;
  }
}

@media screen and (max-width: calc(theme(screens.sm) - 0.05px)) {
  .Section {
    padding: 60px 0 50px;
  }
}

.Section.NoPaddingBottom {
  padding-bottom: 0;
}
.Section.NoPaddingTop {
  padding-top: 0;
}

.Section.SmallPaddingTop {
  padding-top: 52px;
}

/* COMMON BACKGROUNDS */

.White {
}
.Grey {
  background: rgb(var(--color-snow-grey)) var(--asset-bg-section-background-image);
}
.Primary {
  background-color: rgb(var(--color-electric-blue));
}

.NoBackgroundImage {
  background-image: none;
}
