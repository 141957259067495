.TitleWrapper {
  margin-bottom: 40px;
}

@media screen and (max-width: calc(theme(screens.md) - 0.05px)) {
  .TitleWrapper {
    margin-bottom: 35px;
  }
}
@media screen and (max-width: calc(theme(screens.sm) - 0.05px)) {
  .TitleWrapper {
    margin-bottom: 30px;
  }
}

.TitleContainer {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

.Subtitle {
  color: rgb(var(--color-orange));
  font-size: 14px !important;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 700;
  line-height: 90%;
  opacity: 0.8;
}
@media screen and (max-width: calc(theme(screens.lg) - 0.05px)) {
  .Subtitle {
    font-size: 12px !important;
  }
}
