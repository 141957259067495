.Wrapper {
  scrollbar-width: thin;
  scrollbar-color: rgb(var(--color-dark-blue)) transparent;

  margin-left: calc(-1 * theme(spacing.4));
  margin-right: calc(-1 * theme(spacing.4));
}

@media screen(sm) {
  .Wrapper {
    margin-left: calc(-1 * theme(spacing.8));
    margin-right: calc(-1 * theme(spacing.8));
  }
}
@media screen(md) {
  .Wrapper {
    margin-left: calc(-1 * theme(spacing.14));
    margin-right: calc(-1 * theme(spacing.14));
  }
}
@media screen(lg) {
  .Wrapper {
    margin-left: calc(-1 * theme(spacing.24));
    margin-right: calc(-1 * theme(spacing.24));
  }
}
@media screen and (max-width: calc(theme(screens.xl) - 0.05px)) {
  .ScrollableContent {
    min-width: calc(theme(screens.xl) - theme(spacing.48));
  }
  .ScrollableContainer {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: 2px;
  }
}

/* Hide scrollbar */

.ScrollableContainer::-webkit-scrollbar {
  width: 12px;
}

.ScrollableContainer::-webkit-scrollbar-track {
  background: transparent;
}

.ScrollableContainer::-webkit-scrollbar-track {
  background: transparent;
}

.ScrollableContainer::-webkit-scrollbar-thumb {
  background-color: transparent;
  border: 4px solid transparent;
}
