/* prettier-ignore */

@keyframes heroSlideUp {
  0% {
    background-position:
      /* Rock foreground */
      bottom calc(-70px + -20 * var(--depth-fg-rocks) * 1vh)
      left calc(50% + 450px + var(--parallax-x, 0) * var(--depth-fg-rocks) * 1vw),

      /* Runner */
      bottom calc(10px + -20 * var(--depth-runner) * 1vh)
      left calc(50% + 300px + var(--parallax-x, 0) * var(--depth-runner) * 1vw),

      /* Arch */
      bottom calc(10px + -20 * var(--depth-arch) * 1vh)
      left calc(50% + 700px + var(--parallax-x, 0) * var(--depth-arch) * 1vw),

      /* Rock Background */
      bottom calc(-70px + -20 * var(--depth-bg-rocks) * 1vh)
      left calc(-1vw * var(--depth-bg-rocks) + var(--parallax-x, 0) * var(--depth-bg-rocks) * 1vw),

      /* Gradient */
      center,

      /* Gradient */
      center,

      /* Mountain */
      bottom calc(-200px + -10 * var(--depth-back) * 1vh)
      left calc(50% + var(--parallax-x, 0) * var(--depth-back) * -1%);

  }
  100% {
    background-position:
      /* Rock foreground */
      bottom calc(-80px + var(--parallax-y, -1) * var(--depth-fg-rocks) * 1vh)
      left calc(50% + 450px + var(--parallax-x, 0) * var(--depth-fg-rocks) * 1vw),


      /* Runner */
      bottom calc(var(--parallax-y, -1) * var(--depth-runner) * 1vh)
      left calc(50% + 400px + var(--parallax-x, 0) * var(--depth-runner) * 1vw),

      /* Arch */
      bottom calc(10px + var(--parallax-y, -1) * var(--depth-arch) * 1vh)
      left calc(50% + 700px + var(--parallax-x, 0) * var(--depth-arch) * 1vw),

      /* Rock Background */
      bottom calc(-70px + var(--parallax-y, -1) * var(--depth-bg-rocks) * 1vh)
      left calc(-1vw * var(--depth-bg-rocks) + var(--parallax-x, 0) * var(--depth-bg-rocks) * 1vw),

      /* Gradient */
      center,

      /* Gradient */
      center,

      /* Mountain */
      bottom calc(-220px + var(--parallax-y, 0) * var(--depth-back) * 1vh)
      left calc(50% + var(--parallax-x, 0) * var(--depth-back) * -1%);

  }
}

/* prettier-ignore */
.Wrapper {
  background-color: #0052ff;
  background-image:
    url("https://res.cloudinary.com/kavval/image/upload/c_fit,w_414/q_auto:low/f_auto/app/homepage/hero-background-blue-left-rocks_jvepox"),
    linear-gradient(140deg, #0051f9, transparent 50%),
    linear-gradient(173deg, #1e63eb, transparent 70%),
    url("https://res.cloudinary.com/kavval/image/upload/c_lfill,f_auto,w_414,h_656,g_auto,q_auto:low,f_auto/app/homepage/hero-background-blue-mountains-small_fhs1hk");
  background-repeat: no-repeat;
  background-position:
    /* Rock foreground */
    bottom center,

    /* Gradient */
    center,

    /* Gradient */
    center,

    /* Mountain */
    center center;

  background-size: contain, initial, initial, cover;
  min-height: 80vh;
  position: relative;
}

@media screen(sm) {
  /* prettier-ignore */
  .Wrapper {
    background-image:
      url("https://res.cloudinary.com/kavval/image/upload/c_fit,w_900/q_auto:low/f_auto/app/homepage/hero-background-blue-left-rocks_jvepox"),
      linear-gradient(140deg, #0051f9, transparent 50%),
      linear-gradient(173deg, #1e63eb, transparent 70%),
      url("https://res.cloudinary.com/kavval/image/upload/c_lfill,f_auto,w_900,h_656,g_auto,q_auto:low,f_auto/app/homepage/hero-background-blue-mountains-small_fhs1hk");
  }
}

@media screen(md) {
  /* prettier-ignore */
  .Wrapper {
    --depth-fg-rocks: 7.5;
    --depth-runner: 5;
    --depth-arch: 4;
    --depth-bg-rocks: 2;
    --depth-back: 1;
    animation: .5s cubic-bezier(0.12, 1.01, 1, 1) .5s heroSlideUp;
    animation-fill-mode: both;
    transition: background-position .1s;

    background-image:
      url("https://res.cloudinary.com/kavval/image/upload/c_fit,w_900/q_auto:low/f_auto/app/homepage/hero-background-blue-right-rocks_u7jjhw"),
      url("https://res.cloudinary.com/kavval/image/upload/e_background_removal/c_fit,w_500/q_auto:low/f_auto/app/homepage/mathieu-blanchard_bm0bb9.png"),
      url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA5NyIgaGVpZ2h0PSI2NTEiIHZpZXdCb3g9IjAgMCAxMDk3IDY1MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMjIgNjUxaDE1OGMxMiAwIDIyLTExIDIyLTI0VjM4NmMwLTYgMi0xMiA3LTE3bDE1MC0xNTljNC00IDEwLTcgMTYtN2gzNDdjNiAwIDEyIDMgMTYgN2wxNTAgMTU5YzUgNSA3IDExIDcgMTd2MjQxYzAgMTMgMTAgMjQgMjIgMjRoMTU4YzEyIDAgMjItMTEgMjItMjRWMzA1YzAtNy0yLTEyLTctMTdMODI0IDdjLTQtNS05LTctMTUtN0gyODhjLTYgMC0xMSAyLTE1IDdMNyAyODhjLTUgNS03IDEwLTcgMTd2MzIyYzAgMTMgMTAgMjQgMjIgMjRaIiBmaWxsPSIjZmZmIi8+CiAgPHBhdGggZD0iTTIyIDY1MWgxNThjMTIgMCAyMi0xMSAyMi0yNFYzODZjMC02IDItMTIgNy0xN2wxNTAtMTU5YzQtNCAxMC03IDE2LTdoMzQ3YzYgMCAxMiAzIDE2IDdsMTUwIDE1OWM1IDUgNyAxMSA3IDE3djI0MWMwIDEzIDEwIDI0IDIyIDI0aDE1OGMxMiAwIDIyLTExIDIyLTI0VjMwNWMwLTctMi0xMi03LTE3TDgyNCA3Yy00LTUtOS03LTE1LTdIMjg4Yy02IDAtMTEgMi0xNSA3TDcgMjg4Yy01IDUtNyAxMC03IDE3djMyMmMwIDEzIDEwIDI0IDIyIDI0WiIgZmlsbD0idXJsKCNhKSIgZmlsbC1vcGFjaXR5PSIuMSIvPgogIDxkZWZzPgogICAgPGxpbmVhckdyYWRpZW50IGlkPSJhIiB4MT0iNTQ4LjUiIHkxPSIwIiB4Mj0iMTgxLjUiIHkyPSI1NTAuNSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgogICAgICA8c3RvcC8+CiAgICAgIDxzdG9wIG9mZnNldD0iMSIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KPC9zdmc+Cg=="),
      url("https://res.cloudinary.com/kavval/image/upload/c_fit,w_800/q_auto:low/f_auto/app/homepage/hero-background-blue-left-rocks_jvepox"),
      linear-gradient(140deg, #0051f9, transparent 50%),
      linear-gradient(173deg, #1e63eb, transparent 70%),
      url("https://res.cloudinary.com/kavval/image/upload/c_crop,g_west,w_2702/c_fit,w_1920/q_auto:eco/f_auto/app/homepage/hero-background-blue-mountains-small_fhs1hk");

    background-repeat: no-repeat;

    background-size: clamp(31.25rem, 100vh, 56.25rem), clamp(12.5rem, 50vh, 31.25rem), 110vh, auto auto, auto auto, auto auto, auto auto;
  }
}

.Wrapper > div {
  z-index: 2;
}
