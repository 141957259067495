.RawHtml {
  a {
    color: theme(colors.orange);
    font-weight: 600;

    &:not([href*=".finishers.com"]):not([href^="/"]) {
      color: inherit;
      font-weight: 600;
    }

    &:hover {
      color: theme(colors.orange / 80);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    clear: both;
  }

  &:after {
    content: "";
    display: block;
    clear: both;
  }
}
